<template>
  <div :class="['page', { isOld: isOld == 1 ? true : false }]">
    <img src="./img/yellow-bg.png" class="bg" />
    <div class="main">
      <div class="orderDetail">
        <div class="orderDetail-top">
          <div class="title">订单详情</div>
          <div
            class="status"
            v-if="detail.orderStatus == 1"
            style="color: #ffd033"
          >
            进行中
          </div>
          <div
            class="status"
            v-else-if="detail.orderStatus == 2"
            style="color: #2e7bff"
          >
            已完成
          </div>
          <div class="status" v-else style="color: #d5d5d5">已取消</div>
        </div>
        <div class="orderDetail-center">
          <div
            class="item"
            v-for="(item, index) in detail.details"
            :key="index"
          >
            <div class="item-left">
              <img :src="item.image" alt="" />
            </div>
            <div class="item-right">
              <div class="name">
                <div class="name-txt">{{ item.foodName }}</div>
                <div class="price"><span>¥</span>{{ item.countPrice }}</div>
              </div>
              <div class="num">x{{ item.foodNum }}</div>
            </div>
          </div>
        </div>
        <div class="orderDetail-bottom">
          <div class="bottom-info">
            <div class="label">合计</div>
            <div class="info">{{ detail.countPrice }}元</div>
          </div>
          <div class="bottom-info">
            <div class="label">下单时间</div>
            <div class="info">{{ detail.insertTime }}</div>
          </div>
          <div class="bottom-info">
            <div class="label">就餐方式</div>
            <div class="info">
              {{ detail.orderType == 1 ? "堂食" : "外送" }}
            </div>
          </div>
        </div>
      </div>
      <div class="orderDetail">
        <div class="orderDetail-bottom">
          <div class="bottom-info">
            <div class="label">下单人</div>
            <div class="info"></div>
          </div>
          <div class="bottom-info">
            <div class="label">姓名</div>
            <div class="info">{{ detail.receiverName }}</div>
          </div>
          <div class="bottom-info">
            <div class="label">联系方式</div>
            <div class="info">{{ detail.receiverPhone }}</div>
          </div>
        </div>
      </div>
      <div class="orderDetail" v-if="detail.canCancel == '1' && isGLY == '否'">
        <div class="orderDetail-bottom">
          <div class="qxdd">下单后五分钟内可取消订单</div>
        </div>
      </div>
      <div class="orderDetail" v-if="detail.orderStatus == 99">
        <div class="orderDetail-bottom">
          <div class="bottom-info">
            <div class="label">订单取消时间</div>
            <div class="info">{{ detail.cancelTime }}</div>
          </div>
        </div>
      </div>
      <div class="orderDetail" v-if="detail.orderStatus == 2">
        <div class="orderDetail-bottom">
          <div class="bottom-info">
            <div class="label">订单完成时间</div>
            <div class="info">{{ detail.receiveTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="btn"
      v-if="isGLY == '是' && detail.orderStatus == 1"
      @click="submit(2)"
    >
      完成订单
    </div>
    <div
      class="btn"
      v-if="detail.canCancel == '1' && isGLY == '否'"
      @click="submit(99)"
    >
      取消订单
    </div>
  </div>
</template>

<script>
import { handleImg } from "@/utils/utils.js";
import { mapState } from "vuex";
import {
  getFunctionSettingUrl,
  getOrderDetailsUrl,
  userInfoUrl,
  changeOrderStatusUrl,
} from "./api.js";
export default {
  name: "myFoodOrderDetail",
  data() {
    return {
      id: null,
      userInfo: {},
      flag: false,
      detail: {},
      isGLY: null,
      isOld: localStorage.getItem("isOld"),
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.isGLY = this.$route.query.isGLY;
    this.getUserInfo();
  },
  mounted() {
    this.getDetail();
    this.isShowMindList();
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    submit(type) {
      let params = {
        id: this.id,
        orderStatus: type,
        userId: this.userId,
      };
      this.$axios
        .post(`${changeOrderStatusUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.getDetail();
          }
        });
    },
    format2(status) {
      if (status == 1) {
        return "进行中";
      } else if (status == 2) {
        return "已完成";
      } else {
        return "已取消";
      }
    },
    getDetail() {
      let params = {
        id: this.id,
      };
      this.$axios
        .get(`${getOrderDetailsUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            res.data.details.forEach((item) => {
              if (item.image) {
                item.image = handleImg(136, 136, item.image);
              }
            });
            this.detail = res.data;
          }
        });
    },
    // 获取用户信息
    getUserInfo() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(userInfoUrl, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
          }
        });
    },
    isShowMindList() {
      let params = {
        tenantId: this.tenantId,
        applicationName: "金钟健康心",
        functionName: "有权限号码",
      };
      this.$axios
        .get(`${getFunctionSettingUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.settingOn.indexOf(this.userInfo.mobile) != -1) {
              this.flag = true;
            } else {
              this.flag = false;
            }
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  padding-bottom: 60px;
  box-sizing: border-box;
  background: #f9f9f9;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    line-height: 66px;
    text-align: center;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 50px;
  }
  .main {
    padding: 26px 30px 0;
    box-sizing: border-box;
    position: relative;
    .orderDetail {
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 20px;
      .orderDetail-bottom {
        padding: 30px;
        box-sizing: border-box;
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        .qxdd {
          text-align: center;
          font-size: 36px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e02020;
          box-sizing: border-box;
        }
        .bottom-info:nth-last-child(1) {
          margin-bottom: 0;
        }
        .bottom-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 36px;
          .info {
            font-size: 36px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
      .orderDetail-center {
        padding: 28px 32px 5px;
        box-sizing: border-box;
        border-bottom: 2px solid #f5f5f5;
        .item {
          height: 136px;
          width: 100%;
          display: flex;
          margin-bottom: 38px;
          .item-right {
            flex: 1;
            padding: 12px 0 0 20px;
            box-sizing: border-box;
            .num {
              font-size: 32px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
            .name {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              .name-txt {
                flex: 1;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
              }
              .price {
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #e02020;
                span {
                  font-size: 24px;
                }
              }
            }
          }
          .item-left {
            width: 136px;
            height: 136px;
            border-radius: 12px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .orderDetail-top {
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 30px;
        display: flex;
        align-items: center;
        height: 100px;
        border-bottom: 2px solid #f5f5f5;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .bg {
    width: 100%;
    height: 982px;
    position: fixed;
    top: 0;
  }
}
.isOld {
  .btn {
    width: 550px;
    height: 86px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    line-height: 86px;
    text-align: center;
    font-size: 46px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 50px;
  }
  .main {
    padding: 26px 30px 0;
    box-sizing: border-box;
    position: relative;
    .orderDetail {
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 20px;
      .orderDetail-bottom {
        padding: 30px;
        box-sizing: border-box;
        font-size: 46px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        .qxdd {
          text-align: center;
          font-size: 44px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e02020;
          box-sizing: border-box;
        }
        .bottom-info:nth-last-child(1) {
          margin-bottom: 0;
        }
        .bottom-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 36px;
          .info {
            font-size: 44px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
      .orderDetail-center {
        padding: 28px 32px 5px;
        box-sizing: border-box;
        border-bottom: 2px solid #f5f5f5;
        .item {
          height: 156px;
          width: 100%;
          display: flex;
          margin-bottom: 38px;
          .item-right {
            flex: 1;
            padding: 12px 0 0 20px;
            box-sizing: border-box;
            .num {
              font-size: 42px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
            .name {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              .name-txt {
                flex: 1;
                font-size: 48px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
              }
              .price {
                font-size: 48px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #e02020;
                span {
                  font-size: 24px;
                }
              }
            }
          }
          .item-left {
            width: 156px;
            height: 156px;
            border-radius: 12px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .orderDetail-top {
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 30px;
        display: flex;
        align-items: center;
        height: 100px;
        border-bottom: 2px solid #f5f5f5;
        font-size: 42px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .bg {
    width: 100%;
    height: 982px;
    position: fixed;
    top: 0;
  }
}
</style>
